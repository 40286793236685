.space-app-card {
  text-decoration: none;
}

.space-app-card .space-app-card-details * {
  display: block;
}

.space-app-card:focus {
  outline: solid #5cbdfa;
}
.space-app-card:hover .space-app-card-details {
  text-decoration: underline;
}
