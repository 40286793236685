.plan-card {
  display: block;
  padding: 12px;
  border: 2px solid #565656;
  border-radius: 4px;
}

.plan-card-feature {
  margin: 2px -12px;
  padding: 4px 12px;
}

.plan-card.selected {
  border-color: #5cbdfa;
  box-shadow: 0 0 4px 1px #5cbdfa;
  transition: all 0.3s ease;
}

.plan-card:hover {
  cursor: pointer;
}
