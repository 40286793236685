.space-card {
  text-decoration: none;
  min-height: 120px;
  width: 100%;
}

.space-card:hover .space-card-name {
  text-decoration: underline;
}

@media only screen and (max-width: 599px) {
  .space-card {
    min-height: unset;
  }
}
