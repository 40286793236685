.env-tag {
  padding: 4px 8px;
  background-color: white;
  color: #001122;
  border-radius: 8px;
  min-width: 70px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}

.env-tag-dev {
  background-color: #a0ffa3;
}
.env-tag-qa {
  background-color: #ffe589;
}
.env-tag-prod {
  background-color: #ff9292;
}
