.fa-header .fa-logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background-size: cover;
}

.fa-header .app-header-content,
.fa-header .app-header-content .title-and-menu,
.fa-header .app-header-content .menu-large ul,
.fa-header .app-header-content .fa-header-title {
  flex-wrap: nowrap;
}

.fa-header .large-menu-container {
  position: relative;
}
