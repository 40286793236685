.context-menu-button {
  position: relative;
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0;
  padding-right: 1.25rem;
  margin: 0;
  border: none;
  border-radius: 0;
  background: none;
  color: #001122;
}

.context-menu-button .icon {
  position: absolute;
  font-size: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  right: 0;
  top: 0.375rem;
  transform: translateY(1px);
}
