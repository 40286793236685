.fe-se-theme-sample {
  border: 1px solid #c8c8c8;
}

.fe-se-theme-colour {
  border: 1px solid #c8c8c8;
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.fe-se-spacing-example {
  background: #9fd4ff;
  border: 1px solid #c8c8c8;
}

.fe-se-spacing-example-box {
  background: #ffffff;
  padding: 4px;
  font-size: 0.875rem;
}

.fe-se-theme-wcag-test-tag {
  border: 1px solid #c8c8c8;
  padding: 4px;
  font-size: 0.8rem;
}
.fe-se-theme-wcag-test-tag.pass {
  background: #a0ffa3;
}
.fe-se-theme-wcag-test-tag.fail {
  background: #ff9292;
}

.fe-se-theme-label {
  min-width: 90px;
}

.fe-se-hidden {
  visibility: hidden;
}
