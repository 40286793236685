.env-card {
  text-decoration: none;
}

.env-card.disabled {
  background-color: #565656;
  cursor: not-allowed;
}

.env-card:hover:not(.disabled) .env-card-content {
  text-decoration: underline;
}
